<template>
  <div class="media-quiz-progress" :style="cssVars">
    <!-- Upper content -->
    <div class="upper-content" :class="{ 'upper-content-preweek': preWeek }">
      <div class="icon-wrapper">
        <img src="@/assets/img/ic_puzzle.svg" class="icon" alt="puzzle-icon"/>
      </div>
      <div class="text">
        <h2 class="title">{{ $t("OVERVIEW.EXTRA_GAMES_BLOCK.TITLE") }}</h2>
        <p v-if="preWeek" class="description preweek-description">{{ $t('EXTRA_EXERCISES.DESCRIPTION_PRE_WEEK') }}</p>
        <i18n-t
          v-if="amountOfTimesPlayed !== null"
          class="description"
          keypath="OVERVIEW.TIMES_PLAYED"
          tag="p"
        >
          <span>{{ amountOfTimesPlayed }}</span>
        </i18n-t>
      </div>
      <!-- <div v-if="mediaquizProgress !== null" class="progress-bar-wrapper">
      <img class="coin-icon" src="@/assets/icons/icn_bits.svg" alt="">
      <div class="progress-bar">
        <div class="background" />
        <div class="filled" />
        <div class="markers">
          <div v-for="(item, index) in 7" :key="index" class="marker"/>
        </div>
      </div>
      <p class="fill-percentage">{{ cssVars['--progress'] }}</p>
    </div> -->
      <a class="to-media-quiz" :href="extraGamesRoute">
        <button class="base-button blue-button">
          {{ $t("OVERVIEW.CHECK_OUT_EXTRA_GAMES") }}
        </button>
      </a>
    </div>

    <!-- Lower Content -->
    <div v-if="!preWeek" class="lower-content">
      <DailyBitsEarned
        :current-day="currentDay"
        :bits-per-day="dailyBitsEarnedBuffed"
        :max-bits="maxBits"
        :start-date="startDate"
        :end-date="endDate"
        :new-bits="newBits"
        :showLightbulb="false"
        progressBar="number"
        maxHeight="80px"
      />
    </div>
  </div>
</template>

<script>
import DailyBitsEarned from "@/components/elements/missions/DailyBitsEarned.vue";

import {computed} from "@vue/runtime-core";

export default {
  name: "MediaQuizProgress",
  props: {
    mediaquizProgress: {
      type: Number,
      default: null,
    },
    amountOfTimesPlayed: {
      type: Number,
      default: null,
    },
    classId: {
      type: Number,
      required: true,
    },
    currentDay: {
      type: Number,
      required: true,
    },
    bitsPerDay: {
      type: Array,
      required: true,
    },
    maxBits: {
      type: Number,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
    newBits: {
      type: Number,
      required: true,
    },
    preWeek: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    DailyBitsEarned,
  },
  setup(props) {
    const cssVars = computed(() => {
      return {
        "--progress":
          props.mediaquizProgress !== null
            ? `${(props.mediaquizProgress * 100).toFixed(1)}%`
            : "0",
      };
    });

    /// We accidentally cleared a few games, so to make visual sense, we buffed the daily bits if they are <20 away from the maximum up to the max.
    const dailyBitsEarnedBuffed = computed(() => {
      const maxBits = props.maxBits
      const currentDay = props.currentDay - 1
      return props.bitsPerDay.map((e, index) => e > maxBits
      - 20 && index !== currentDay ? maxBits : e)
    })

    const extraGamesRoute = computed(() => {
      let route = `${process.env.VUE_APP_ROOT_GAME}/extra-games?`
      if (props.classId) {
        route += `class-id=${props.classId}`
      }
      return route
    })

    return {
      extraGamesRoute,
      cssVars,
      dailyBitsEarnedBuffed,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";

.sub-title {
  color: var(--gray-light_03);
  margin-bottom: rem(12);
}

.media-quiz-progress {
  width: 100%;
  background-color: white;
  border-radius: 15px;
  padding: rem(20) rem(20) rem(20) rem(12);

  .upper-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: rem(24);
  }

  .upper-content-preweek {
    align-items: flex-start
  }

  .lower-content {
  }

  .icon-wrapper {
    border-radius: rem(10);
    margin-right: rem(10);
    background-color: var(--green_light);
    padding: rem(10);
    display: flex;
    justify-content: center;
    align-items: center;
        

    .icon {
      display: block;
      width: rem(30);
      height: rem(30);
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: rem(4);

    .title {
      font-weight: 600;
      line-height: rem(22);
      text-transform: uppercase;
    }

    .description {
      opacity: 0.75;
      color: #373b52;
      font-size: rem(14);
      line-height: rem(17);
    }

    .preweek-description {
      margin-right: rem(16);
      max-width: rem(400);
    }
  }

  .progress-bar-wrapper {
    border-radius: rem(30);
    background: #dfe9fc;
    height: rem(40);
    display: flex;
    align-items: center;
    padding: rem(8) rem(14);
    gap: rem(12);
    margin: 0 auto;

    .coin-icon {
      width: 32px;
    }

    .progress-bar {
      width: rem(130);
      height: rem(24);
      border-radius: rem(14.48);
      position: relative;
      overflow: hidden;

      .background {
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: inherit;
        background: rgba(white, 0.6);
      }

      .filled {
        position: absolute;
        overflow: hidden;
        left: -10%;
        height: 100%;
        width: calc(var(--progress, 0) + 10%);
        border-radius: inherit;
        background: linear-gradient(
            180deg,
            #ff9e78 0%,
            #ffe597 26.81%,
            #fcf6e5 38.88%,
            #fee193 48.47%,
            #f0a460 84.04%,
            #ffe597 100%
        );
      }

      .markers {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-evenly;

        .marker {
          width: rem(1);
          height: 100%;
          background-color: rgba(#615c9f, 0.3);
        }
      }
    }

    .fill-percentage {
      height: fit-content;
      font-size: rem(14);
      font-weight: 500;
      line-height: rem(17);
    }
  }

  .to-media-quiz {
    margin-left: auto;

    .blue-button {
      background-color: #0e4e95;
      white-space: nowrap;
      color: white;
      padding: rem(11) rem(24);
    }
  }
}
</style>
